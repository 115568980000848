<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created() {
    if(!location.href.includes('mzt') && !location.href.includes('yjt') && !location.href.includes('kmyx')){
      if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        if(location.href.includes('jyb')){
          this.$router.replace('/mobile_jyb')
        }else{
          this.$router.replace('/mobile')
        }     
      } else {
        if(location.href.includes('jyb')){
          this.$router.replace('/pc_jyb')
        }else{
          this.$router.replace('/pc')
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>